import * as React from "react";
import {NavLink} from "react-router-dom";
import {NavDropdown} from "react-bootstrap";
import moduleCss from "./AppMenu.module.css"
import FileCopyIcon from "@mui/icons-material/FileCopy";
import RussenRottenshildClub from "../../Pages/russen rottenshild/RussenRottenshildClub";



function AppMenu() {

    return (
        <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown" >

                    {/*Первый блок выпадающего меню*/}
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                О КЛУБЕ
                            </a>
                            <ul className="dropdown-menu " >
                                {/*<li><NavLink className="dropdown-item " style= {{fontFamily: "Arial", fontSize: "40px", }}  to="/news">НОВОСТИ</NavLink></li>*/}
                                <li><NavLink className="dropdown-item " to="/news">НОВОСТИ</NavLink></li>

                                {/*<NavDropdown.Divider />*/}
                                <li><NavLink className="dropdown-item" to="/generalInformation">ОБЩАЯ ИНФОРМАЦИЯ</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/partnerClub">КЛУБЫ-ПАРТНЕРЫ И ФИЛИАЛЫ КФРТ</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/expert">СПЕЦИАЛИСТЫ КФРТ</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/fellowship">СТАТЬ ЧЛЕНОМ КФРТ</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/notFound">БЛАНКИ И ДОКУМЕНТЫ</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/exchangeOfMetricsForPedigree">ОБМЕН МЕТРИКИ НА РОДОСЛОВНУЮ</NavLink></li>
                                <li><NavLink className="dropdown-item" to="/brandingAndChipping">ЧИПИРОВАНИЕ</NavLink></li>
                                <li><a className="dropdown-item" href="#">ВАКЦИНАЦИЯ</a></li>
                                <li><a className="dropdown-item" href="#">ГРУМИНГ (УХОД ЗА ШЕРСТЬЮ И КОГТЯМИ)</a></li>
                                {/*<li><a className="dropdown-item" href="#">ЗООМАГАЗИН подменю (ГОТОВЫЕ КОРМА , натуральное ПИТАНИЕ ,ЛАКОМСТВА ИГРУШКИ И АМУНИЦИЯ)</a></li>*/}
                                <li><a className="dropdown-item" href="#">МУЗЕЙ СОБАКОВОДСТВА  КФРТ</a></li>
                                <li><a className="dropdown-item" href="#">ГОСТИНИЦА для СОБАК</a></li>
                                <li><a className="dropdown-item" href="#">КОНТАКТЫ И РЕКВИЗИТЫ</a></li>
                                <li><a className="dropdown-item" href="#">АВТОРСКОЕ ПРАВО</a></li>
                            </ul>
                        </li>

                        {/*Второй блок выпадающего меню*/}
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                УЧЕБНЫЙ ЦЕНТР
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">ДРЕССИРОВКА</a></li>
                                <li><a className="dropdown-item" href="#">ПОДГОТОВКА К ВЫСТАВКАМ (ХЭНДЛИНГ)</a></li>
                                <li><a className="dropdown-item" href="#">ДОГ ФИТНЕСС</a></li>
                                <li><a className="dropdown-item" href="#">КИНОЛОГИЧЕСКИЙ СПОРТ</a></li>
                                <li><a className="dropdown-item" href="#">ДНАТАСКА ОХОТНИЧЬИХ СОБАК</a></li>
                                <li><a className="dropdown-item" href="#">КУРСЫ КИНОЛОГОВ</a></li>
                                <li><a className="dropdown-item" href="#">КУРСЫ ЮНЫХ КИНОЛОГОВ</a></li>
                                <li><a className="dropdown-item" href="#">СЕМИНАРЫ</a></li>
                                <li><a className="dropdown-item" href="#">МАСТЕР КЛАССЫ</a></li>
                                <li><a className="dropdown-item" href="#">ФОТОСЕССИИ С СОБАКАМИ</a></li>
                                <li><a className="dropdown-item" href="#">АРЕНДА ЗАЛОВ</a></li>
                            </ul>
                        </li>

                        {/*Третий блок выпадающего меню*/}
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                ВЛАДЕЛЬЦАМ
                            </a>
                            <ul className="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/dogOwner">ВЫ ГОТОВЫ СТАТЬ ВЛАДЕЛЬЦЕМ СОБАКИ ?</NavLink></li>
                                <li><a className="dropdown-item" href="#">ОТВЕТСТВЕННОСТЬ ЗА СОБАКУ</a></li>
                                <li><a className="dropdown-item" href="#">ВЫБОР ПОЛА</a></li>
                                <li><NavLink className="dropdown-item" to="/BreedSelection">ВЫБОР ПОРОДЫ</NavLink></li>
                                <li><a className="dropdown-item" href="#">ОПАСНОСТЬ «ЧЕРНЫХ РАЗВЕДЕНЦЕВ»</a></li>
                            </ul>
                        </li>

                        {/*Четвертый блок выпадающего меню*/}
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                РАЗВЕДЕНИЕ
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">ДОКУМЕНТЫ ДЛЯ ПЛЕМЕННОЙ РАБОТЫ</a></li>
                                <li><a className="dropdown-item" href="#">НАЧИНАЮЩЕМУ ЗАВОДЧИКУ</a></li>
                                <li><a className="dropdown-item" href="#">ОПЫТНОМУ ЗАВОДЧИКУ</a></li>
                                <li><a className="dropdown-item" href="#">ВЛАДЕЛЬЦУ ПРОИЗВОДИТЕЛЯ</a></li>
                                <li><a className="dropdown-item" href="#">КОБЕЛИ НА ВЯЗКУ</a></li>
                                <li><a className="dropdown-item" href="#">ПЛЕМЕННЫЕ СУКИ</a></li>
                            </ul>
                        </li>

                        {/*Пятый блок выпадающего меню*/}
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                КЛУБНЫЕ ЩЕНКИ
                            </a>
                            <ul className="dropdown-menu">
                                <li><NavLink className="dropdown-item" to="/RussenRottenshildClub">ЩЕНКИ КЛУБА RUSSEN ROTTENSCHILD</NavLink></li>
                                {/*<li><a className="dropdown-item" href="#">ЩЕНКИ КЛУБА RUSSEN ROTTENSCHILD</a></li>*/}
                            {/*    <li><a className="dropdown-item" href="#">ПИНЧЕРЫ ШНАУЦЕРЫ ДОГООБРАЗНЫЕ</a></li>*/}
                            {/*    <li><a className="dropdown-item" href="#">ТЕРЬЕРЫ</a></li>*/}
                            {/*    <li><a className="dropdown-item" href="#">ТАКСЫ</a></li>*/}
                            {/*    <li><a className="dropdown-item" href="#">ШПИЦЕОБРАЗНЫЕ И ПРИМИТИВНЫЕ</a></li>*/}
                            {/*    <li><a className="dropdown-item" href="#">ГОНЧИЕ</a></li>*/}
                            {/*    <li><a className="dropdown-item" href="#">ЛЕГАВЫЕ</a></li>*/}
                            {/*    <li><a className="dropdown-item" href="#">РЕТРИВЕРЫ</a></li>*/}
                            {/*    <li><a className="dropdown-item" href="#">ДЕКОРАТИВНЫЕ И КОМПАНЬОНЫ</a></li>*/}
                            {/*    <li><a className="dropdown-item" href="#">БОРЗЫЕ</a></li>*/}
                            </ul>
                        </li>

                        {/*Шестой блок выпадающего меню*/}
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                ВЫСТАВКИ
                            </a>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">ВЫСТАВКИ СОБАК</a></li>
                                <li><a className="dropdown-item" href="#">ПЛЕМЕННЫЕ СМОТРЫ  (ПС)</a></li>
                                <li><a className="dropdown-item" href="#">СТИРОВАНИЕ ПОВЕДЕНИЯ  (Т1Т2)</a></li>
                                <li><NavLink className="dropdown-item" to="/testAndCompetitions">ИСПЫТАНИЯ И СОРЕВНОВАНИЯ</NavLink></li>
                            </ul>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
            );

}

export default AppMenu;