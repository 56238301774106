import partnerClubsStyle from "./PartnerClubsAndBranchesOfKFTPage.module.css"


const PartnerClubsAndBranchesOfKFT = () => {
    return (
        <div>
            <div className={partnerClubsStyle.h1}><h1>КФРТ развивает связи с собаководами в городах РТ и РФ</h1></div>

            <div className={partnerClubsStyle.h3}>
                <h3>
                    <b>Партнеры и филиалы КФРТ в республике Татарстан:</b>
                </h3>
            </div>

            <div >
                <p>
                    <b>КИНОЛОГИЧЕСКИЙ ЦЕНТР РЕСПУБЛИКИ ТАТАРСТАН (КЦ РТ) г. Казань</b>
                    Член РКФ с 2008г,
                    член федерации РФОС( Российская Федерация Охотничьего Собаководства)
                    - полный спектр кинологической деятельности, все породы собак.<br/>
                    Председатель клуба: <b>СЕРГЕЙ НИКОЛАЕВИЧ ЛАПТЕВ Тел: +7-906-327-04-42</b>

                </p>
            </div>

            <div >
                <div className={partnerClubsStyle.hr}></div>
                <p>
                    <b>КЛУБ ЛЮБИТЕЛЕЙ ПОРОДИСТЫХ ЖИВОТНЫХ РЕСПУБЛИКИ ТАТАРСТАН</b>
                    (КЛПЖ РТ) г Казань
                    Член РКФ с 2013 г.
                    Член Федерации РФ СС (Российская Федерация Служебного Собаководства)
                    - полный спектр кинологической деятельности, все породы собак и другие виды домашних питомцев<br/>
                    Руководитель клуба: <b>СЕРГЕЙ НИКОЛАЕВИЧ ЛАПТЕВ Тел: +7-906-327-04-42</b>
                </p>
            </div>
            <div className={partnerClubsStyle.hr}></div>
            <div >
                <p>
                    <b>ФИЛИАЛ КИНОЛОГИЧЕКОЙ ФЕДЕРАЦИИ РТ в г. ЧИСТОПОЛЬ, ТАТАРСТАН</b><br/>
                    Руководитель филиала: <b>КИСЛОВ ЮРИЙ АЛЕКСАНДРОВИЧ Тел: +7-917-918-78-58</b>
                </p>
            </div>

            <div >
                <div className={partnerClubsStyle.hr}></div>
                <p>
                    <b>ФИЛИАЛ КИНОЛОГИЧЕСКОЙ ФЕДЕРАЦИИ РТ в г. ЗЕЛЕНОДОЛЬСК, ТАТАРСТАН</b><br/>
                    Руководитель Филиала: <b> ЛАПТЕВ СЕРГЕЙ НИКОЛАЕВИЧ +7-906-327-04-42</b>
                </p>
            </div>

            <div >
                <div className={partnerClubsStyle.hr}></div>
                <p>
                    <b>ФИЛИАЛ КИНОЛОГИЧЕСКОЙ ФЕДЕРАЦИИ РТ в г. Волжск, РМЭ</b><br/>
                    Руководитель Филиала:<b>ЧЕБУРАХТИНА ИРИНА Тел: +7-960-092-54-30</b>
                </p>
            </div>

            <div >
                <div className={partnerClubsStyle.hr}></div>
                <p>
                    Руководитель филиала <b>КФРТ в г. Набережные Челны:</b><br/>
                    <b>
                    ЧЕРНЫШОВА ЕЛЕНА АНАТОЛЬЕВНА Тел: +7-905-370-41-95
                </b>
                </p>
            </div>
            <siv></siv>
        </div>
    );
}
export default PartnerClubsAndBranchesOfKFT;