import React from "react";
import exchange from "./BreedSelection.module.css";
import Divider from "@mui/material/Divider";

const BreedSelection = (props) => {
    return (
        <div>
            <div>
                <nav className="navbar navbar-expand-lg ">
                <div className="container-left">
                    <br/>
                    <ul >
                        <li><a className="dropdown-item" href="#">ПАСТУШЬИ</a></li>
                        <li><a className="dropdown-item" href="#">ПИНЧЕРЫ ШНАУЦЕРЫ ДОГООБРАЗНЫЕ</a></li>
                        <li><a className="dropdown-item" href="#">ТЕРЬЕРЫ</a></li>
                        <li><a className="dropdown-item" href="#">ТАКСЫ</a></li>
                        <li><a className="dropdown-item" href="#">ШПИЦЕОБРАЗНЫЕ И ПРИМИТИВНЫЕ</a></li>
                        <li><a className="dropdown-item" href="#">ГОНЧИЕ</a></li>
                        <li><a className="dropdown-item" href="#">ЛЕГАВЫЕ</a></li>
                        <li><a className="dropdown-item" href="#">РЕТРИВЕРЫ</a></li>
                        <li><a className="dropdown-item" href="#">ДЕКОРАТИВНЫЕ И КОМПАНЬОНЫ</a></li>
                        <li><a className="dropdown-item" href="#">БОРЗЫЕ</a></li>
                    </ul>
                </div>
                    </nav>
                <div>
                    <br/>
                    <p > В нашем мире существует  около <span className={exchange.selection}> 400 пород собак,</span> признанных   международной кинологической федерацией,
                        отличающихся  размерами, внешним  видом, типом шерсти,  направлением использования (служебные охотничьи, декоративные),
                        особенностями поведения, наличием или отсутствием служебных качеств. Перед покупкой собаки следует решить,
                        какая порода подойдет именно Вам и взвесить все «за» и «против». Собака должна максимально
                        соответствовать требованиям своего потенциального хозяина, его характеру, привычкам. Ведь человек выбирает собаку «под себя».
                        Надо  точно представлять, что именно Вы хотите от собаки , какую роль в вашей жизни  она  будет выполнять.
                        Чтобы определиться, ознакомьтесь с 8-ю главными вопросами,  которые помогут принять правильное решение.
                    </p>
                    <br/>

                    <h3 className={exchange.headingSelection}> 1. Для  каких целей  Вы  хотите приобрести собаку ?</h3>

                    <p > Породы изначально выводились для выполнения определённых задач. Существует разделение пород по
                        их предназначению в соответствии с <span className={exchange.selection}>классификацией FCI на 10 групп.</span> Но это деление не всегда
                        может быть однозначным для новичков, т.к. собаки   здесь классифицируются  только по назначению,
                        без учетов размера.<br/>
                        <span className={exchange.selectTitles}>Служебные собаки</span> - группа пород, используемых человеком для выполнения какой-либо
                        работы самостоятельно или под управлением хозяина. Обычно это крупные животные с активно-оборонительной реакцией.
                        Их характерными признаками являются, способность к дрессировке,  преданность хозяину, контактность.
                        Это собаки-охранники, телохранители, сторожа,  пастушьи собаки. Они широко используются в пограничных
                        войсках как ищейки, сторожевые, при досмотре багажа, грузов и транспортных средств на таможнях.
                        К ним относятся немецкие и бельгийские овчарки, доберманы, бернский зененхунд, русский чёрный терьер,
                        Для сторожевой службы наиболее пригодны следующие породы: среднеазиатская овчарка, кавказская
                        овчарка, дог, ротвейлер, леонбергер, ризеншнауцер, ховаварт.<br/>
                        <span className={exchange.selectTitles}>Охотничьи собаки</span>  имеют один общий признак — сильно развитый охотничий инстинкт,
                        который при умелой дрессировке человек может использовать в своих целях.
                        Охотничьи собаки  классифицируются по способам их применения: норные и истребители грызунов (таксы, терьеры),
                        лайки , гончие (бладхаунд, бигль) легавые (английский сеттер, веймаранер) ,  ретриверы  и собаки работающие
                        по подноске дичи из воды  ( лабрадоры, голден- флэт коат ретриверы и другие)  борзые (русская псовая борзая,
                        ирландский волкодав. Как истребители грызунов зарекомендовали себя терьеры.<br/>
                        <span className={exchange.selectTitles}> Ездовые собаки</span> и сейчас применяются для перевозки людей и грузов в северных районах
                        страны (хаски, аляскинский маламут, самоед).<br/>
                        <span className={exchange.selectTitles}>Декоративные собаки </span>(собаки-компаньоны) тоже имеют своё предназначение.
                        С мелкими из них можно посещать  общественные места (чихуахуа, русский той, йоркширский терьер),
                        с более крупными их собратьями (пудели) приятно заниматься более активной деятельностью - гулять,
                        ходить в походы, ездить на велосипеде.
                        Итак, определитесь, зачем вам собака, и круг пород существенно сузиться.</p>


                    <h3 className={exchange.headingSelection}> 2. Ваши физические возможности</h3>

                    <p >Неизбежно стоит учесть  физические возможности   основного  хозяина и всех  членов  семьи.
                        Если  хозяином будет  крепкий и мужчина - Вы можете завести себе любую собаку, хоть большую, хоть маленькую.
                        Однако взрослые сильные мужчины чаще всего не имеют достаточно времени, чтобы заниматься собакой - поэтому
                        учитывайте физические силы членов Вашей семьи (хрупких женщин и маленьких детей).
                        Итак, детям и женщинам не следует приобретать крупных (а иногда и некоторых средних активных) собак.
                        Это не касается профессиональных кинологов - но перед ними и не стоит вопрос выбора породы.
                    </p>


                    <h3 className={exchange.headingSelection}> 3. Соответствует ли  размер Вашего жилища с размером будущей собаки</h3>


                    <p > Насколько просторно в Вашем доме? Исходя из того, живете Вы за городом или в городской квартире,
                        подумайте, собака каких размеров больше подходит для Ваших жилищных условий: маленькая, средняя или большая.
                        Собакам крупных пород (сенбернар, немецкая овчарка, ирландский сеттер) требуется много движения и
                        для них желательно иметь собственный просторный дом с большим двором. Если Вы живете в городе в квартире,
                        Вам лучше завести пуделя, пинчера или цвергшнауцера. Следует отметить, что жители многих стран в
                        Европе исходят именно из этого. Там на улицах городов не встретишь на улице крупных пород собак,
                        да и средние породы там представлены в небольшом количестве.
                        При покупке щенка обязательно поинтересуйтесь у хозяина и требованиями к пространству для собаки,
                        и будущими размерами собаки (если Вы не знаете, каких размеров достигает взрослый представитель
                        данной породы, хотя об этом можно почитать в интернете) и возможными отклонениями от стандарта.
                        Высота собаки измеряется в холке - от земли до последнего позвонка перед шейным отделом позвоночника.</p>

                    <h3 className={exchange.headingSelection}> 4.  Возможность выгула собаки. </h3>


                    <p >Тому, кто ищет себе собаку для городской квартиры и не имеет возможностей для прогулок в парках,
                        но не хочет заводить себе совсем мелкую декоративную собаку, стоит обратить своё внимание на
                        универсальные породы средних размеров . Представители этих пород лучше всего приспосабливаются к
                        условиям большого города. Если Вы покупаете щенка  служебной , ездовой  или охотничьей породы , помните,
                        что они нуждаются в свободном движении, как минимум по 2-3 часа в сутки, при отсутствии движения собаки
                        этих пород начинают проявлять деструктивное, разрушительное  поведение,  из за избытка невостребованной энергии.
                        Тем, кто живет за городом, рядом с лесом, стоит знать, что у собаки с гончим и охотничьим инстинктом
                        моментально проявляется охотничий инстинкт, и они могут убежать в лес в поисках добычи. Если Вы не
                        занимаетесь охотой, возможно, Вам лучше остановить свой выбор на служебных породах.
                    </p>

                    <h3 className={exchange.headingSelection}> 5. Возможность дрессировать собаку или заниматься специфическим видом активности </h3>


                        <p > Любая собака , независимо от породы  потребует от Вас времени  на дрессировку по послушанию
                            (общий курс дрессировки, послушная городская собака)  или  обучению специальным видам активности
                            (аджилити,  гонки  на собачьих упряжках,  охота, специальные служебные навыки).
                            Подумайте, какой вид активности будет Вам интересен и возможен. </p>


                    <h3 className={exchange.headingSelection}> 6. Тип головы собаки </h3>


                        <p>Какое выражение морды  собаки   вы предпочитаете? Все породы  собак имеют  разделение по типу голов -  длинномордые
                            (долихоцефалы)- колли, борзые ,  морда средней длины  (мезоцефалы)- лайки, пудели,
                            овчарки  и короткомордые (брахицефалы)-  мопс, бостон терьер, бордоский дог, боксер.
                            Часто от типа головы зависит  потребность в степени  физической активности собаки,
                            ее чувствительность  к жаркой погоде. Не  последним  является вопрос эстетического восприятия головы.</p>

                    <h3 className={exchange.headingSelection}> 7. Шерсть и другие особенности экстерьера </h3>

                    <p>Если Вам нравится заниматься с шерстью (может Вы профессиональный грумер по призванию) - заводите
                        длинношерстную породу. И смотрится красиво, эффектно и шерсти начешете - будут у Вас вязанные
                        вещи из шерсти собственной собаки. Длинная шерсть защищает от холода, но на ней остается больше
                        грязи и уход за ней сложнее. Длинношерстные собаки линяют осенью и весной, а их шерсть трудно
                        убирается с ковров, подушек и диванов. Это можно предотвратить, регулярно вычесывая собаку.
                        Относительно мало шерсти в квартирах владельцев пуделей и других кудрявых пород (керри-блю-терьеры, бедлингтоны).
                        Т.е. они, конечно, тоже линяют, но всё то, что с них падает получается скрученным в аккуратный
                        комочек - подобрали, выбросили и у Вас опять чисто. Но их также надо регулярно чесать и стричь.<br/>
                        Удобно держать в квартире собаку с шерстью средней длины с подшерстком и гладкошерстных собак
                        (последние, правда, плохо защищены от непогоды). А шерсть по всей квартире и с длинношерстных,
                        и с короткошерстных пород распределяется одинаково - только убирать короткие колючие шерстинки сложнее, чем длинные.
                        Зато короткошерстных удобнее готовить к выставкам - протёр тряпочкой - и в ринг!<br/>

                        Нет шерсти от голых собак (но их надо смазывать кремом и вообще серьёзно ухаживать за кожей)
                        и от жесткошерстных (терьеры, шнауцеры) собак - но их надо тримминговать каждые 2-3 месяца.<br/>

                        Окрас  тоже имеет значение. Например, Вам могут нравиться белые собаки, но в нашем стране
                        такая собака становится не белой уже после первой прогулки. С другой стороны, если у Вас в
                        квартире в основном светлая мебель, на ней тёмная шерсть будет сразу заметна.<br/>

                        Коротконогие собаки (бассет-хаунды, таксы, мелкие терьеры) сильнее пачкаются в сырую погоду,
                        им сложно передвигаться по глубокому снегу. Собакам с короткой мордочкой свойственны одышка,
                        храп, предрасположенность к тепловым ударам, сердечно-сосудистым заболеваниям.
                        Длинные уши блад-хаундов и спаниелей легко травмируются во время прогулок и собачьих драк,
                        попадают в миску с едой. Следует также помнить, что они чаще страдают воспалением среднего уха.

                    </p>

                    <h3 className={exchange.headingSelection}> 8. Темперамент и особенности характера. </h3>

                    <p>Темпераменты собаки и хозяина должны совпадать. Хотя могут быть и тут свои исключения.
                        Так, склонным к депрессии людям подойдут собаки с характером, настроенным на постоянный позитив.
                        Не все собаки одной породы имеют одинаковый характер (в каждой породе могут быть злые и добрые,
                        спокойные и активные, доминантные и подчинённые), зачастую характер щенкам передают их родители,
                        многое будет зависеть от воспитания щенка в маленьком возрасте. Однако свои предрасположенности
                        характера существуют в каждой породе.<br/>

                        Людям, нуждающимся в покое, не следует приобретать собак, склонных к агрессивному поведению или
                        очень темпераментных: фокстерьер, бультерьер, эрдельтерьер, вельш-терьер, бедлингтон-терьер.
                        Подвижную, энергичную собаку легче держать в хорошей физической форме, но и прогулки ей требуются более длительные.
                        Во время дрессировки такие собаки невнимательны, плохо слушаются хозяина. Они требуют спокойного, уравновешенного отношения.<br/>

                        Владельцам, имеющим флегматичный темперамент, не стоит выбирать собак охотничьих пород: терьеров, такс, спаниелей.
                        К тому же, плохо дрессированные собаки со взрывным темпераментом (колли, доберманы, шнауцеры) могут создать массу
                        неудобств для своего хозяина.<br/>

                        Для тех, кто ищет «золотую середину» - собаку уравновешенную, не агрессивную, молчаливую, следует
                        обратить свое внимание на представителей следующих пород: чау-чау, такса, спаниель, французский бульдог,
                        бигль, пудель, бассет-хаунд. Естественно, не все они одинаковы, но желаемых качеств можно достичь умелой дрессировкой.
                        Спокойный, уравновешенный характер имеют сенбернары, ньюфаундленды, московские сторожевые.
                        Заставить такую собаку (сенбернар, ньюфаундленд) активно двигаться на прогулке сложно.
                        Руководствуясь данными критериями по подбору собаки, Вам легче будет определиться с выбором породы
                        Вашего будущего домашнего любимца. Когда вы ответите на все эти вопросы - у Вас получится буквально 2-3
                        подходящих Вам пород собак. А из 2-3 выбирать уже просто, гораздо проще, чем из 500. И тогда Вы пойдёте
                        на выставку собак и поговорите с владельцами выбранных вами пород... И, наконец, решите, какая из них ДЕЙСТВИТЕЛЬНО ВАША!
                        Не испытывайте иллюзий , что у вас будет воспитанная собака - они такими не рождаются, а становятся.
                        Приобретая собаку любой породы  помните, что вам необходимо выделить время  на воспитание и дрессировку  нового члена семьи.<br/>

                        Помните, что  вы берете собаку , которая фактически станет членом вашей семьи на ближайшие  10 лет.
                        Собаке должно быть уютно в Вашем доме, что каждой породе присущи свои особенности, в соответствии
                        с которыми собаку нужно воспитывать, ухаживать за ней</p>


                </div>
            </div>
        </div>
);
}
export default BreedSelection;