import React from "react";
import mainPageModule from "../index/MainPage.module.css";
import may from "../../../img/show/future/may.jpg";
import june from "../../../img/show/future/june.jpg";


const News = () => {
    return(
        <div>
            {/*<div>*/}
            {/*    <br/>*/}
            {/*    <img className={mainPageModule.img} src={may} />*/}
            {/*    <br/><br/>*/}
            {/*    <img className={mainPageModule.img} src={june} />*/}
            {/*    <br/><br/><br/><br/>*/}

            {/*</div>*/}
        </div>
    );
}
export default News;