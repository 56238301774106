import React from "react";
import exchange from "./DogOwner.module.css";
import Divider from "@mui/material/Divider";

const DogOwner = (props) => {
    return (
        <div>
            <div>
                <div>
                    <br/><br/>
                    <p className={exchange.indent}> Сегодня многие владельцы покупают  щенков у заводчиков, поэтому заключение
                        договора купли-продажи становится как никогда актуальным, ведь этот документ – гарантия прав как
                        покупателя, так и продавца.</p>
                    <br/>

                    <h3 className={exchange.selection}> ЧТО ТАКОЕ ДОГОВОР КУПЛИ-ПРОДАЖИ ЩЕНКА ИЛИ КОТЕНКА И ДЛЯ ЧЕГО ОН НУЖЕН?</h3>

                    <p > Согласно статье 454 ГК РФ, договор купли-продажи – это соглашение между двумя сторонами,
                        что одна из них — продавец — передаст другой стороне — покупателю — определенную вещь,
                        когда получит за нее оплату. Суть договора купли-продажи заключается в переходе права
                        собственности от продавца к покупателю. Продавец обязуется передать покупателю щенка или котенка
                        вместе со всеми принадлежностями (если они имеются) и документами, включая ветеринарный паспорт
                        и документы о происхождении, если это имеет значение, в установленный в договоре срок.
                        А покупатель обещает принять это и заплатить определённую сумму. Сумма устанавливается,
                        как правило, договорная. Договор купли-продажи щенка или котенка в итоге будет подтверждать
                        право собственности на животное. Право собственности перейдет от продавца к покупателю только
                        в момент её передачи.</p>


                    <p className={exchange.selection}> В КАКОЙ ФОРМЕ ЗАКЛЮЧАЕТСЯ ДОГОВОР?</p>

                    <p >Договор заключается в письменной форме в виде документа, подписанного сторонами (продавцом и покупателем)
                        и содержащего соглашения по всем существенным условиям. </p>

                    <p className={exchange.selection}> КАКОВО СОДЕРЖАНИЕ ДОГОВОРА? ЧТО ДОЛЖНО БЫТЬ ОБЯЗАТЕЛЬНО ОТРАЖЕНО В ДОГОВОРЕ?</p>
                     <p > Договор не имеет четко утвержденной формы, но есть сведения, которые должны быть в договоре. Они должны включать:</p>

                    <ol>


                        <li> Место и дату совершения сделки;</li>
                        <li> Сведения о продавце (заводчике) и покупателе: Ф.И.О., полные паспортные данные;</li>
                        <li> Подробную характеристику объекта продажи (животного): вид животного, породу, пол, возраст, окрас с его возможными особенностями, при наличии указывают также номер клейма или чипа;</li>
                        <li> Стоимость – без нее договор действителен, но в спорных случаях цену придется устанавливать с помощью экспертов и через суд. Порядок расчётов;</li>
                        <li> Ответственности сторон;</li>
                        <li> Порядок передачи щенка или котенка;</li>
                        <li> Подписи и реквизиты сторон.</li>
                    </ol>

                    <p className={exchange.selection}> ЕСЛИ ЗАВОДЧИК ХОЧЕТ ВКЛЮЧИТЬ В ДОГОВОР ДОПОЛНИТЕЛЬНЫЕ ПУНКТЫ, НАПРИМЕР ТРЕБОВАНИЕ О ВОЗВРАТЕ
                        ЖИВОТНОГО В СЛУЧАЕ ЖЕСТОКОГО ОБРАЩЕНИЯ, ВОЗМОЖНО ЛИ ЭТО?</p>


                    <p > По данному вопросу нет правовой практики и данный пункт не считаю
                        целесообразным в договоре. Если будут обнаружены случаи жестокого обращения с животным, решать
                        вопрос о праве собственности будет суд. Более того, о свидетельстве данных противоправных
                        действий надо будет обратиться в органы полиции.</p>

                    <p className={exchange.selection}> КАК ЧАСТО ВОЗНИКАЮТ СПОРНЫЕ МОМЕНТЫ В ОТНОШЕНИИ КУПЛИ-ПРОДАЖИ ЖИВОТНЫХ? НАСКОЛЬКО ШИРОКА СУДЕБНАЯ ПРАКТИКА В ОТНОШЕНИИ ТАКИХ СПОРОВ?</p>

                    <p >Судебная практика в отношении купли-продажи животных набирает обороты,
                        имеется уже достаточный юридический опыт по таким спорам. В основном споры идут в области
                        качества приобретенного животного, например, в случае, если покупатель приобрел животное с
                        заболеваниями или с генетическими отклонениями. Но оценку здоровья животного проводит только
                        судебный ветеринарный эксперт при объективном исследовании.</p>

                        <p className={exchange.selection}> КАКОВА ПРОЦЕДУРА РАСТОРЖЕНИЯ ДОГОВОРА КУПЛИ-ПРОДАЖИ ЩЕНКА ?</p>

                        <p > Договор купли-продажи может быть расторгнут по обстоятельствам, связанным с волеизъявлением
                            одной или обеих его сторон, на основании соглашения сторон (п. 1 ст. 450 ГК РФ) – для этого требуется
                            заключение соответствующего соглашения, которое должно быть совершено в той же форме, что и сам договор.
                            В противном случае договор расторгается только в судебном порядке.</p>

                    <p className={exchange.selection}> КТО МОЖЕТ ЯВЛЯТЬСЯ ПРОДАВЦОМ И ПОКУПАТЕЛЕМ?</p>

                        <p>Продавцами и покупателями могут быть как физические, так и юридические лица, а также
                            государственные и муниципальные образования. Граждане при этом должны быть дееспособными,
                            а юридические лица — правоспособными. <br/>
                            автор: Шинкаренко А.Н.</p>

                    <p className={exchange.attention}> Для членов клуба  КФРТ  разработан стандартный договор купли — продажи, котторый  предоставляется заводчикам при оформлении помета.</p>

                </div>
            </div>
        </div>
);
}
export default DogOwner;