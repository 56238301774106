import React from "react";
import headerModule from "./Header.module.css";
import banner from "../../img/banner.png"
import {NavLink} from "react-router-dom";

const Header = () => {
    return <header className={headerModule.header}>
        <div className={headerModule.banner}>
            {/*<a href="/"><img src={banner} width='1650px' height='200px'/></a>*/}
            <NavLink to="/"><img className={headerModule.banner} src={banner}  /></NavLink>
        </div>
    </header>
}

export default Header;