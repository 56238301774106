import partnerClubsStyle from "../../partnerclubs/PartnerClubsAndBranchesOfKFTPage.module.css";
import expertStyle from "./ExpertPage.module.css"
import dyshyeva from "../../../img/expert/bushueva.jpg"
import kugurakova from "../../../img/expert/Kugurakova.jpg"
import volodina from "../../../img/expert/volodina.jpg"
import grin from "../../../img/expert/grin.jpg"
import noPhoto from "../../../img/expert/no-photo-male.jpg"
import myhavtdzieva from "../../../img/expert/myhamyatzyanova.jpg"
import laptev from "../../../img/expert/laptev.jpg"
import aglavieva from "../../../img/expert/aglavieva.jpg"
import gybaidylina from "../../../img/expert/gybaidylina.jpg"
import nikolaeva from "../../../img/expert/nikolaeva.jpg"
import myhamadieva from "../../../img/expert/myhamadieva.jpg"
import toropceva from "../../../img/expert/toropceva.jpg"
import kyzina from "../../../img/expert/kyzina.jpg"
import semenova from "../../../img/expert/semenova.jpg"
import lavrova from "../../../img/expert/lavrova.jpg"
import gilfanova from "../../../img/expert/gilfanova.jpg"
import kykyshkin from "../../../img/expert/kykyshkin.jpg"
import tumenova from "../../../img/expert/tumenova.jpg"
import popova from "../../../img/expert/popova.jpg"
import lebedeva from "../../../img/expert/lebedeva.jpg"
import divakova from "../../../img/expert/divakova.jpg"
import berieva from "../../../img/expert/berieva.jpg"
import horosheva from "../../../img/expert/horosheva.jpg"
import ahmetzyanova from "../../../img/expert/ahmetzyanova.jpg"
import timiryasova from "../../../img/expert/timiryasova_A.jpeg"

const Expert = () => {
    return (
        <div>

            <div>
                {/*<div className={expertStyle.hr}></div>*/}
                <div><br/><br/></div>
                <div><img className={expertStyle.img} src={grin}/></div>
                <h1 className={expertStyle.h1Color}>Гринь Ольга Анатольевна</h1>
                <div>
                    <p>
                        Председатель КФРТ<br/>
                        кандидат биологических наук<br/>
                        судья РКФ –FCI по все породам собак и рабочим качествам собак<br/>
                        кермайстер, доктор ветеринарной медицины.<br/>
                        Владелец питомника «Руссен Роттеншильд» ротвейлер, доберман.<br/>
                        Тел: +7-905-021-06-37
                    </p>
                    <div><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={laptev}/></div>
                <h1 className={expertStyle.h1Color}>Лаптев Сергей Николаевич</h1>
                <div>
                    <p>
                        Директор КФРТ и зоомагазина Верный Друг <br/>
                        Специалист – кинолог.<br/>
                        Тел: +79063270442<br/>
                    </p>
                    <div><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><img className={expertStyle.img} src={volodina}/></div>
                <h1 className={expertStyle.h1Color}>Володина Алена Викторовна</h1>
                <div>
                    <p>
                        Специалист-кинолог. <br/>
                        Судья-стажер.<br/>
                        Председатель секции «РЕТРИВЕРЫ»<br/>
                        Владелец питомника лабрадор и голден ретриверов, вельш корги пемброк<br/>
                        «Жемчужина Булгар».<br/>
                        Тел: +7-905-025-74-67
                        <br/><br/>
                    </p>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={lavrova}/></div>
                <h1 className={expertStyle.h1Color}>Лаврова Светлана Александровна</h1>
                <div>
                    <p>
                        Специалист кинолог. В кинологии с 1995 г.<br/>
                        Председатель комитета КФРТ по дрессировке и рабочим качествам собак, клуба «Ноуз Ворк»<br/>
                        Судья по рабочим качествам собак<br/>
                        Инструктор РКФ( ОКД,ВН, ноузворк), судья РКФ(ноузворк) , судья Минспорта ( многоборье
                        кинологов),<br/>
                        тренер сборной Приволжского округа( многоборье кинологов)<br/>
                        Владелец питомника ягдтерьеров и немецких овчарок «Дерзкий охотник».<br/>
                        Тел: +7 917 297-21-72
                    </p>
                </div>
            </div>
            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={timiryasova}/></div>
                <h1 className={expertStyle.h1Color}>Тимирясова Анастасия</h1>
                <div>
                    <p>
                        Специалист кинолог, хэндлер.<br/>
                        Владелец собак чемпионов пород миниатюрный пинчер,  вельш корги пемброк.<br/>
                        Тел: +7 965 620-80-76
                    </p>
                    <div><br/><br/><br/><br/><br/></div>
                </div>
            </div>
            <div>
                <div className={expertStyle.hr}></div>
                <div><img className={expertStyle.img} src={aglavieva}/></div>
                <h1 className={expertStyle.h1Color}>Аглавиева Алина</h1>
                <div>
                    <p>
                        Специалист кинолог, хэндлер, судья стажер.<br/>
                        Председатель секции породы «СИБА» КФРТ. <br/>
                        Тел: +7-917-888-70-97
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>
            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={gybaidylina}/></div>
                <h1 className={expertStyle.h1Color}>Губайдуллина Сюмбель Альберовна</h1>
                <div>
                    <p>
                        Специалист кинолог, хэндлер.<br/>
                        Владелец кобеля производителя и. чемпиона породы вельш корги пемброк.<br/>
                        Тел: +7 919-635-08-04
                    </p>
                    <div><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={gilfanova}/></div>
                <h1 className={expertStyle.h1Color}>Гильфанова Диляра Махмудовна</h1>
                <div>
                    <p>
                        Специалист кинолог.<br/>
                        Председатель секции породы БОРДОСКИЙ ДОГ<br/>
                        Владелец питомника «ГИЛЬДИБОРД» бордоские доги, русские тои глакошерстные.<br/>
                        Тел: +7 952 037-71-66
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={kugurakova}/></div>
                <h1 className={expertStyle.h1Color}>Кугуракова Влада Владимировна</h1>
                <div>
                    <p>
                        Кандидат технических наук<br/>
                        Специалист кинолог. <br/>
                        Председатель секции породы сибирсих хаски КФРТ<br/>
                        Владелец питомника сибирских хаски «СЕМАРГЛ»<br/>
                        Тел: +7-917-269-58-84
                    </p>
                    <div><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={kykyshkin}/></div>
                <h1 className={expertStyle.h1Color}>Кукушкин Денис</h1>
                <div>
                    <p>
                        Специалист кинолог судья стажер. <br/>
                        Ветеринарный фельдшер,<br/>
                        Председатель комитетета КФРТ по призовому фонду мероприятий.<br/>
                        Тел: +7 917 286-72-73
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={semenova}/></div>
                <h1 className={expertStyle.h1Color}>Семенова Влада Владимировна</h1>
                <div>
                    <p>
                        Специалист кинолог. <br/>
                        Председатель секции породы золотистых ретриверов КФРТ<br/>
                        Владелец питомника голден ретриверов «Красно солнышко».<br/>
                        Тел: +7 905 376-57-00
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={tumenova}/></div>
                <h1 className={expertStyle.h1Color}>Тюменева Людмила Владимировна</h1>
                <div>
                    <p>
                        Специалист кинолог, судья стажер.<br/>
                        Председатель секции породы «АКИТА» КФРТ<br/>
                        Владелец питомника акит «АКИХИРО».<br/>
                        Тел: +7-937-327-71-52
                    </p>
                    <div><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/><br/></div>
                <div><img className={expertStyle.img} src={nikolaeva}/></div>
                <h1 className={expertStyle.h1Color}>Николаева Елизавета Васильевна</h1>
                <div>
                    <p>
                        Специалист кинолог судья стажер. <br/>
                        Заводчик бультерьеров с 1992 г.<br/>
                        Председатель секции бультерьер КФРТ.<br/>
                        Владелец питомника «RULES BULL» Бультерьер стандартный и миниатюрный, чихуа хуа.<br/>
                        Тел: +7 917 297-21-72
                    </p>
                    <div><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={toropceva}/></div>
                <h1 className={expertStyle.h1Color}>Торопцева Екатерина Дмитриевна</h1>
                <div>
                    <p>
                        Специалист кинолог, судья стажер.<br/>
                        Председатель секции борзых и миниатюрных бультерьеров КФРТ.<br/>
                        Владелец питомника «ИЛ ТО БЕНД» миниатюрных бультерьеров и салюки.<br/>
                        Тел: +7-919-686-96-74
                    </p>
                    <div><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={noPhoto}/></div>
                <h1 className={expertStyle.h1Color}>Герасимова Анастасия</h1>
                <div>
                    <p>
                        Специалист кинолог. <br/>
                        Председатель секции породы шарпей КФРТ<br/>
                        Владелец питомника «РОСИНАНТ» шарпей, бультерьер стандарт и мини.<br/>
                        Тел: +7-909-308-17-03
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={myhamadieva}/></div>
                <h1 className={expertStyle.h1Color}>Мухамадиева Миляуша</h1>
                <div>
                    <p>
                        Специалист кинолог. <br/>
                        Председатель секции ЦВЕГШНАУЦЕР КФРТ<br/>
                        Владелец питомника «АЗГИЛЕАНТ» цвергшнауцер.<br/>
                        Тел: +7-952-037-71-66
                    </p>
                    <div><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={popova}/></div>
                <h1 className={expertStyle.h1Color}>Попова Екатерина</h1>
                <div>
                    <p>
                        Специалист кинолог, судья стажер.<br/>
                        Председатель секции породы лаготто романьоло КФРТ<br/>
                        Владелец питомника лаготто романьоло «ФЕНОМЕНО ФОРТУНАТО».<br/>
                        Тел: +7-965-585-30-75
                    </p>
                    <div><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={myhavtdzieva}/></div>
                <h1 className={expertStyle.h1Color}>Мухамедзянова Лилия</h1>
                <div>
                    <p>
                        Специалист кинолог судья стажер. <br/>
                        Председатель секции ротвейлер КФРТ<br/>
                        Судья-стажер по экстерьеру.<br/>
                        Тел: +7-953-486-92-01
                    </p>
                    <div><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={berieva}/></div>
                <h1 className={expertStyle.h1Color}>Бариева Наиля Ахмедовна</h1>
                <div>
                    <p>
                        Кандидат физико - математических наук. Доцент.<br/>
                        Владелец питомника "Монбонами"<br/>
                        Тел: +7-917-267-21-02
                    </p>
                    <div><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={horosheva}/></div>
                <h1 className={expertStyle.h1Color}>Хорошева Евгения</h1>
                <div>
                    <p>
                        Специалист кинолог.<br/>
                        Владелец питомника «ФРЕШ СТАР» пород мопс, пти брабансон, той пудель, мальтезе.<br/>
                        Тел: +7-960-038-09-87
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>


            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={noPhoto}/></div>
                <h1 className={expertStyle.h1Color}>Кузнецова Наталья</h1>
                <div>
                    <p>
                        Специалист кинолог.<br/>
                        Владелец питомника «КУБИК РУБИК» бостон терьер, мопс, той пудель.<br/>
                        Тел: +7-952-046-55-73
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={noPhoto}/></div>
                <h1 className={expertStyle.h1Color}>Талакова Ольга</h1>
                <div>
                    <p>
                        Специалист кинолог. <br/>
                        Председатель секции ШПИЦ КФРТ<br/>
                        Владелец питомника «Олярия» немецкий шпиц той и малый.<br/>
                        Тел: +7-950-310-11-73
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={noPhoto}/></div>
                <h1 className={expertStyle.h1Color}>Ионова Галина</h1>
                <div>
                    <p>
                        Специалист – кинолог.<br/>
                        Владелец питомника «Дамерино».<br/>
                        Тел: +7-987-410-07-00<br/>
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={kyzina}/></div>
                <h1 className={expertStyle.h1Color}>Кузина Ирина</h1>
                <div>
                    <p>
                        Специалист кинолог. <br/>
                        Судья стажер<br/>
                        Председатель секции Чау-чау<br/>
                        Владелец питомника «Сэриель Веон».br/>
                        Тел: +7-953-999-28-87
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>

            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={divakova}/></div>
                <h1 className={expertStyle.h1Color}>Дивакова Наталья Сергеевна</h1>
                <div>
                    <p>
                        Специалист кинолог. <br/>
                        Владелец питомника «Фром Ледженд Стайл» Французский бульдог, Бостон терьер.<br/>
                        Тел: + 7-906-115-68-98
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>


            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={lebedeva}/></div>
                <h1 className={expertStyle.h1Color}>Лебедева Светлана Николаевна</h1>
                <div>
                    <p>
                        Специалист кинолог.<br/>
                        Владелец питомника «ТОЧНО В ЦЕЛЬ» - немецкий боксер, бостон терьер.<br/>
                        Тел: + 7-903-314-51-36
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>


            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={noPhoto}/></div>
                <h1 className={expertStyle.h1Color}>Баландина Оксана</h1>
                <div>
                    <p>
                        Специалист кинолог тел. <br/>
                        Председатель секции йоркширский терьер КФРТ<br/>
                        Заводчик пород йоркширский терьер, джек-рассел-терьер, немецкий шпиц.<br/>
                        Тел: +7 950 320-20-28
                    </p>
                    <div><br/><br/><br/><br/><br/><br/><br/><br/></div>
                </div>
            </div>


            <div>
                <div className={expertStyle.hr}></div>
                <div><br/></div>
                <div><img className={expertStyle.img} src={ahmetzyanova}/></div>
                <h1 className={expertStyle.h1Color}>Ленара Ахметзянова</h1>
                <div>
                    <p>
                        {/*Специалист кинолог тел. <br/>*/}
                        {/*Председатель секции йоркширский терьер  КФРТ<br/>*/}
                        {/*Заводчик пород йоркширский терьер, джек-рассел-терьер, немецкий шпиц.<br/>*/}
                        {/*Тел: +7 950 320-20-28*/}
                    </p>
                    <div><br/></div>
                </div>
            </div>

        </div>
    );
}

export default Expert;