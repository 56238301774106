import React from "react";
import mainPageModule from "./MainPage.module.css"
import september from "../../../img/show/future/september_2023.jpeg";
import october from "../../../img/show/future/October.jpeg";
import november from "../../../img/show/future/november.jpeg";
import december from "../../../img/show/future/December.jpeg";
const MainPage = (props)=>{
    return(
        <div>
            <div className={mainPageModule.div_img}>

                <img className={mainPageModule.div_img_september} src={september} />
                <br/><br/>
                <img className={mainPageModule.img} src={october} />
                <br/><br/><br/><br/>
                <br/><br/>
                <img className={mainPageModule.img} src={november} />
                <br/><br/><br/><br/>
                <br/><br/>
                <img className={mainPageModule.img} src={december} />
                <br/><br/><br/><br/>

            </div>

        </div>

    )
}
export default MainPage;
