import * as React from "react";
import DEXTER from "../../../img/russen_rottrnshild/Dexter_head.JPG"
import TEYA from "../../../img/russen_rottrnshild/teya_head.jpg"
import june from "../../../img/show/future/june.jpg";

const RussenRottenshildClub = () => {
    return (
        <div>
            <div >
                <h1>Открыто бронирование на щенков от пары:
                    щенки родились 16 марта 2023 г.</h1>
            </div>
            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <h3>DEXTER V.D. WEISSENSTADT
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    RUSSEN ROTTENSCHILD TEYA</h3>
            </div>
            <div >

                <img src={DEXTER} width='600' height='600'/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={TEYA} width='600' height='600'/>

            </div>

            <br/>
           <h3> подрбности на сайте:
               <a className="dropdown-item" href="http://rottweiler1.ru/" ><font size="5" color="red" ><b>RUSSEN ROTTENSCHILD</b></font></a></h3>
        </div>
    );
}
export default RussenRottenshildClub;