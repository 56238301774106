import React from "react";
import newsBlockModule from "./NewsBlock.module.css";
import expo from "../../../img/newsBlockImg/expo.jpg"
import test from "../../../img/newsBlockImg/test_and_Competitions.jpeg";
import russen_rottenshild from "../../../img/newsBlockImg/russen_rottenshild.png";
import {NavLink} from "react-router-dom";
import RussenRottenshildClub from "../../Pages/russen rottenshild/RussenRottenshildClub";

const NewsBlock = () => {
    return <div className={newsBlockModule.content}>


        <div>
            <img className={newsBlockModule.img} src={expo} />
            <br/><br/><br/>
        </div>
        <div>
            <img src={test} width="250px" height="357px"/>
            <br/><br/>
        </div>
        {/*<div>*/}
        {/*    <NavLink className="dropdown-item" to="/RussenRottenshildClub"><p><font color='#8b0000' size="3">DEXTER V.D. WEISSENSTADT<br/> & RUSSEN ROTTENSCHILD TEYA</font></p>*/}
        {/*        <img src={russen_rottenshild} width="250px" height="150px"/></NavLink>*/}
        {/*</div>*/}
    </div>
}

export default NewsBlock;
