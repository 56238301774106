import React from "react";
import clubModule from './Club.module.css';
import BrandingAndChipping from "../../Pages/brandingAndChipping/BrandingAndChipping";

const Club = () => {
    return <div className={clubModule.content}>
        <div className={clubModule.heading}>

            <h1>КИНОЛОГИЧЕСКАЯ ФЕДЕРАЦИЯ РЕСПУБЛИКИ ТАТАРСТАН</h1>
            <hr color='gainsboro'/>
        </div>
        <div className={clubModule.h2}>
        <h2>Член Российской Кинологической Федерации и  Федерации ОАНКОО.<br/>
            КФРТ объединяет в своих рядах собаководов – любителей и владельцев питомников.</h2>
        </div>

        <div className={clubModule.h2}>
            <h2>Задачи и основные направления деятельности КФРТ:</h2>
        </div>
        <div className={clubModule.ul}>
            <ul>
                <li>поддержка  и развитие породистого  собаководства;</li>
                <li>ведение племенной  работы с породистыми собаками;</li>
                <li>регистрация щенков и оформление метрик щенков на родословные РКФ;</li>
                <li>идентификация собак -сертифицированное клеймение (клейматором HAUTPNER Германия -5 мм для мелких собак и 7 мм для крупных);</li>
                <li>чипирование собак чипами международной системы ISO (электронная идентификация с занесением в базу данных КФРТ);</li>
                <li>оформление племенной документации и регистрация вязок собак;</li>
                <li>консультации по подбору пар для вязок породистых собак;</li>
                <li>консультации по выращиванию, кормлению, воспитанию собак;</li>
                <li>консультации по подбору и приобретению породистых собак;</li>
                <li>организация секций по всем породам собак;</li>
                <li>организация выставок, испытаний, соревнований, тестирований породистых собак с целью повышения качества племенной работы;</li>
                <li>организация курсов по подготовки собак к выставкам;</li>
                <li>организация курсов по индивидуальной дрессировки собак, по послушанию, развитию охранных навыков, коррекции поведения.</li>
                <li>груминг и хэндлинг собак от квалифицированных кинологов;</li>
                <li>обеспечение кормами и зоотоварами,  в том числе с доставкой на дом для владельцев зарегистрированных в клубе собак;</li>
            </ul>
        </div>
    </div>
}

export default Club;