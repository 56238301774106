import React from "react";
import "./NavBar.css"
import AppMenu from "./Bootstrap_Appmenu/AppMenu";
import CustomizedMenus from "./material_app_menu/CustomizedMenus";

const NavBar = () => {

    return <nav className='nav'>
        <div>
            <AppMenu/>
            {/*<CustomizedMenus/>*/}
            </div>
    </nav>
}
export default NavBar;
