import React from "react";
import fellowshipModule from "./Fellowship.module.css";
import Divider from "@mui/material/Divider";

const Fellowship = (props) => {
    return (
        <div>
            <div>
                <div>
                    <h1 className={fellowshipModule.h1}>Как стать членом КФРТ ?</h1>
                </div>
                <div>
                    <p className={fellowshipModule.p}>
                        Написать заявление о вступлении в КФРТ, и ознакомится с правами и обязанностями члена клуба .
                        Бланк заявления можно скачать здесь
                        Оплатить вступительный и годовой взнос в клуб (лично в офиах клуба на Павлюхина 100 или Ферма 2
                        д.76 , или он-лайн , без посещения офиса клуба по любому из контактных телефонов клуба)
                        Получить регистрационное свидетельство на собаку лично или по почте.
                        В срок действия членства пользоваться правами и преимуществами членства, соблюдая при этом
                        обязанности члена клуба и этические нормы ответственного собаковода.
                        Для чего быть членом КФРТ ?
                    </p>
                </div>

                <div>
                    <h3 className={fellowshipModule.h3}>
                        ДЛЯ ЧЛЕНОВ КЛУБА РАБОТАЮТ ПРОГРАММЫ :
                    </h3>
                </div>
                <div >
                    <ul className={fellowshipModule.p}>
                        <li> БЕСПЛАТНЫЕ КОНСУЛЬТАЦИИ И ПРАКТИЧЕСКИЕ ЗАНЯТИЙ ПО ПОСЛУШАНИЮ</li>
                        <li> ОБУЧАЮЩИЕ ПРОГРАММЫ ДЛЯ ВЛАДЕЛЬЦЕВ</li>
                        <li>ШКОЛА ЮНОГО КИНОЛОГА ДЛЯ ДЕТЕЙ И ПОДРОСТКОВ</li>
                        <li> СКИДКИ НА УЧАСТИЕ В КЛУБНЫХ МЕРОПРИЯТИЯХ, ПЛЕМЕННЫХ СМОТРАХ</li>
                        <li> ОБЕСПЕЧЕНИЕ КОРМАМИ СО СКИДКАМИ</li>
                        <li> ДЕТСКИЙ САД ДЛЯ ЩЕНКОВ И СОБАК С ВОЗМОЖНОСТЬЮ СОЦИАЛИЗИРУЮЩЕЙ ВОСПИТАТЕЛЬНОЙ ДРЕССИРОВКИ</li>
                        <li>ХЭНДЛИНГ И ПОЛНОЕ СОПРОВОЖДЕНИЕ ДРЕССИРОВОЧНОЙ И ВЫСТАВОЧНОЙ АКТИВНОСТИ ВАШЕЙ СОБАКИ.</li>
                        <li> КОНСУЛЬТАЦИИ ВЕТЕРИНАРОГО ВРАЧА , ВАКЦИНАЦИЯ , ПРОФИЛАКТИЧЕСКИЕ СЕЗОННЫЕ ОБРАБОТКИ</li>
                        <li>ЛЮБЫЕ ВОПРОСЫ СВЯЗАННЫЕ С КИНОЛОГИЧЕСКОЙ ДЕЯТЕЛЬНОСТЬЮ.</li>
                    </ul>
                </div>

                <div>
                    <h3 className={fellowshipModule.h3}> В КФРТ Вы можете получить консультации по общим вопросам:</h3>
                    <ul className={fellowshipModule.p}>
                        <li> По подбору породы, учитывая все пожелания Вас и вашей семьи.</li>
                        <li>Индивидуальному выбору щенка и породных особенностях собак;</li>
                        <li> Содержанию собаки и уходу за ней;</li>
                        <li> Воспитанию и обучению собаки;</li>
                        <li> Вопросам здоровья собаки;</li>
                        <li> Кормлению собаки</li>
                    </ul>
                </div>
                <div>
                    <h3 className={fellowshipModule.h3}>
                        В КФРТ Вы можете получить консультации для заводчиков по разведению собак и по оформлению пометов.
                    </h3>
                    <ul className={fellowshipModule.p}>
                        <li> подбору пары для вязок</li>
                        <li> оформление пометов</li>
                        <li>племенной документации</li>
                        <li> Ведение пометов и подготовка племенной документации</li>
                        <li> консультации по продажам щенков</li>
                        <li> организация рекламных фотосессий для пометов</li>
                    </ul>
                </div>

                <div>
                    <h3 className={fellowshipModule.h3}> В КФР Т можно получить консультации ветеринарного врача и кинологов :</h3>
                    <ul className={fellowshipModule.p}>
                        <li>По вакцинации (прививкам) собак и кошек; По чипированию и внесению чипа в базу даыхн</li>
                        <li> По вопросам ветеринарии</li>
                        <li> По обследованию, актировке и клеймению щенков и пометов</li>
                        <li> По обучению и дрессировке Вашей собаки по всем видам дисциплин</li>
                        <li>По оформлению документов на щенков и собак</li>
                        <li> По уходу за Вашей собакой - груминг, гигиена</li>
                    </ul>
                </div>
                <div>
                    <h3 className={fellowshipModule.h3}>
                        ВАЖНЕЙШАЯ МИССИЯ КФРТ - ЭТО ПРАВИЛЬНОЕ ВОСПИТАНИЕ, СОЦИАЛИЗАЦИЯ И ДРЕССИРОВКА СОБАК !
                    </h3>
                </div>
                <div>
                    <h3 className={fellowshipModule.h3}>
                        Для этой цели в КФРТ работает Учебный центр для практических занятий по всем видам активности
                        собак и владельцев, а также дрессировки и социализации собак. В КФРТ проводятся:
                    </h3>
                    <ul className={fellowshipModule.p}>
                        <li> Групповые занятия по всем кинологическим дисциплинам</li>
                        <li>Дрессировка по программам: Социализация, ОКД, BH ( УГС), ЗКС, IPO</li>
                        <li>Натаска и дрессировка охотничьих собак</li>
                        <li> Ноуз –ворк трениги и соревнования</li>
                        <li>Обучению отдельным командам, трюкам , приемам по желанию владельца</li>
                        <li>Воспитание щенков, программы предусмотрены с 3х месяцев</li>
                        <li> Коррекция поведения</li>
                        <li>Как итог воспитатитьной и дрессировочной деятельности квартально проводятся ИСПЫТАНИЯ И
                            СОРЕВНОВАНИЯ собак по ВСЕМ ВИДАМ КИНОЛОГИЧЕСКИХ НОРМАТИВНЫХ ДИСЦИПЛИН.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3 className={fellowshipModule.h3}>
                        КФРТ ПРОВОДИТ СЕРТИФИЦИРОВАННЫЕ ПЛЕМЕННЫЕ СМОТРЫ - ЭТО НЕОБХОДИМЫЙ и ЗНАЧИТЕЛЬНЫЙ ДЕНЬ В ЖИЗНИ
                        ПОРОДИСТОЙ СОБАКИ - ПЕРВИЧНАЯ ОЦЕНКА ЭКСТЕРЬЕРА , КОНСТИТУЦИИ и ТЕМПЕРАМЕНТА СОБАКИ.
                    </h3>
                    <p className={fellowshipModule.p}>
                        Успешное прохождене племенного смотра дает право допуска в племенное разведение собаки при
                        наличии у нее родословной РКФ-FCI
                    </p>

                    <h3 className={fellowshipModule.h3}>
                        БОЛЬШОЕ ЗНАЧЕНИЕ ИМЕЕТ ВЫСТАВОЧНАЯ ДЕЯТЕЛЬНОСТЬ- ВАЖНЫЙ ИНСТРУМЕНТ ОТБОРА ЛУЧШИХ СОБАК ДЛЯ
                        РАЗВЕДЕНИЯ !
                    </h3>
                    <h3 className={fellowshipModule.h3}> Клуб информирует о предстоящих выставках и целесообразности участия в каждой конкретной
                        выставке;</h3>
                    <ul className={fellowshipModule.p}>
                        <li> Своевременно зарегиструет собаку для участия в выставке</li>
                        <li>Клуб поможет подготовить собаку к показу на выставке</li>
                        <li>Клуб поможет подготовить шерсть и внешний вид Вашей собаки к выставке</li>
                        <li>Организует группу собаководов для участия в иногородних выставках</li>
                        <li> Обучим Вас как правильно выставлять собаку - на групповых или индивидуальных занятиях с кинологом</li>
                        <li> Клуб проводит курсы для владельцев, как самому подготовиться к выставке (начальные навыки поведения в ринге для владельца)</li>
                        <li> Подготовим и выставим Вашу собаку на любой выставке по Вашему выбору</li>
                    </ul>
                    <h3 className={fellowshipModule.h3}> В клубе можно приобрести:</h3>
                    <ul className={fellowshipModule.p}>
                        <li> Предметы и аксессуары для содержания и воспитания собаки</li>
                        <li> Предметы и средства ухода и гигиены для Вашей собаки</li>
                        <li> Вспомогательные и декоративные аксессуары</li>
                        <li> Правильный корм для Вашей собаки</li>
                        <li> ВЕТЕРИНАРНЫЕ ПРЕПАРАТЫ И ВАКЦИНЫ</li>
                    </ul>
                </div>
                <div>
                    <h3 className={fellowshipModule.h3}> В КЛУБЕ ЕСТЬ ПОДГОТОВКА К ВЫСТАВКЕ С ХЕНДЛЕРОМ ИЛИ САМОСТОЯТЕЛЬНО В ХЭНДЛИНГ-ЗАЛЕ НАШЕГО
                        УЧЕБНОГО ЦЕНТРА ПО АДРЕСУ КАЗАНЬ УЛ. ФЕРМА 2 Д. 76
                    </h3>
                </div>
            </div>
        </div>
    );
}
export default Fellowship;