import React from "react";
import exchange from "./ExchangeOfMetricsForPedigree.module.css";
import Divider from "@mui/material/Divider";

const ExchangeOfMetricsForPedigree = (props) => {
    return (
        <div>
            <div>
                <div>
                    <h1 className={exchange.h1}> ОБМЕН МЕТРИКИ НА РОДОСЛОВНУЮ</h1>

                    <h3 className={exchange.h3}> ОФОРМИТЬ РОДОСЛОВНУЮ РКФ НА ВАШУ СОБАКУ В КФРТ</h3>

                    <p > Предоставить метрику щенка с заполненной графой « владелец и адрес»
                        лично в офисе клуба Павлюхина 100 в рабочие дня с 14 до 19 ч.
                        Или без личного присутствия прислать метрику ват сап по тел клкба или на емайл клуба KFRT1.RU

                        Оплатить целевой вступительный взнос на изготовление родословной в офисе или по реквизитам клуба
                        онлайн в зависимости от того, какой вариант родословной Вам нужен (о вариантах см. ниже)
                        Готовность электронного варианта родословной до 30 рабочих дней, бумажная выписка поступает
                        в клуб в течении 60 рабочих дней . Узнать о готовности документа можно по телефонам или
                        ватсап клуба в рабочие дни с 14 до 19 ч.</p>


                    <p > Внимание !!! Особенности оформления родословной РКФ !</p>

                    <p >Ниже Вы можете ознакомиться с правилами оформления родословной более
                        подробно.
                        Первичным документом в системе РКФ является метрика щенка, которая выдается заводчиком при
                        передаче
                        щенка владельцу. метрика должна быть выдана заводчиком из РФ ОТ системы РКФ.
                        Для обмена метрики щенка на родословную владелец собаки должен обратиться в
                        Кинологическую Федерацию РТ, которая является членом РКФ с правом ведения документооборота.</p>
                    <p className={exchange.selection}> Что такое РОДОСЛОВНАЯ ?</p> <p className={exchange.p}> - это
                    документ
                    о чистопородном происхождении международного образца,
                    признаваемый во всем мире, подтверждающий, что Ваша собака чистопородная и зарегистрирована в
                    ВЕРК.</p>
                    <p className={exchange.selection}>ВЕРК </p> <p className={exchange.p}> – это Всероссийская единая
                    родословной книга РКФ. </p>
                    <p >Родословная РКФ является выпиской из ВЕРК.
                        На собак с метрикой РКФ возможно оформление родословной в одном из двух вариантов:</p>
                    <ul>
                        <li>
                            СВИДЕТЕЛЬСТВО О ПРОИСХОЖДЕНИИ ЕДИНОГО ОБРАЗЦА. (полная родословная)
                        </li>

                    </ul>

                    <ol>
                        Такая родословная позволяет собаке :

                        <li> использоваться в племенном разведении, при условии выполнения необходимых требований ПП
                            РКФ</li>
                        <li> участвовать в любых зоотехнических мероприятиях в России и за рубежом,</li>
                        <li> оформлять любые национальные и интернациональные титулы.</li>
                        <li> Удостоверяет право владения собакой на национальном и международном уровнях</li>
                    </ol>

                    <p > В случае передачи собаки во владение иностранному гражданину в
                        Свидетельстве о происхождении единого
                        образца проставляется помета «Export». Оформляется на двух языках (русском и английском) и
                        содержит
                        следующую информацию: регистрационный номер, порода собаки (с указанием разновидности при
                        наличии),
                        кличка собаки, дата рождения, пол, буквенный код и номер клейма (обязательно), номер чипа
                        (опционально), окрас, фамилия, имя и адрес регистрации заводчика, фамилия, имя и адрес
                        регистрации
                        владельца собаки, основная информация о трех поколениях предков собаки (номера родословных,
                        полные
                        клички, титулы, рабочие испытания, результаты тестирования здоровья и психики).</p>

                    <ul>
                        <li>СВИДЕТЕЛЬСТВО О РЕГИСТРАЦИИ СОБАКИ В ВЕРК. Родословная одного колена предков.</li>
                    </ul>

                    <p > Такая родословная нужна: для собак любимцев пет класа, собак
                        спортсменов или
                        если Вы не уверены в том, что хотите заниматься разведением собак:</p>
                    <ol>
                        <li> Не дает права на участие в разведении.</li>

                        <li> Дает право на участие в зоотехнических мероприятиях национального уровня</li>

                        <li>Дает право на оформление национальных титулов.</li>

                        <li>Удостоверяет право владения собакой на уровне РФ</li>

                        <li> Не является основанием для регистрации собаки в племенной книге другой страны (в случае
                            выезда
                            владельца на постоянное местожительство за рубеж или передачи собаки во владение
                            иностранному
                            гражданину нужно оформить Свидетельство о происхождении единого образца с пометкой
                            «Export»).
                            Содержит все основные сведения о самой собаке и об одном колене ее предков
                        </li>
                    </ol>

                    <p className={exchange.attention}> Важно! При желании, владелец собаки имеющей, 2 вариант
                        родословной, может в любое время заказать
                        1
                        вариант с сооветствующей доплатой 1500р , если например решит принять участие в племенном
                        разведении
                        или продать собаку за рубеж.</p>

                    <p > С 1 декабря 2019 года принимаются заявки только на изготовление
                        родословных нового образца,
                        независимо от даты рождения собаки.</p>

                    <p className={exchange.selection}>ОФОРМЛЕНИЕ РОДОСЛОВНОЙ НА СОБАКУ в КФРТ шаг за шагом:</p>


                    <ul><li>вариант без посещения офиса клуба, он-лайн.</li></ul>
                    <ol>
                        <li> Подготовьте метрику щенка, заполните печатными четкими буквами графы "владелец", "адрес
                            владельца"
                            ( по паспорту)</li>
                        <li> Скачайте и заполните "Согласие на обработку персональных данных", заполните все пустые
                            строчки,
                            подпишитесь живой подписью
                        </li>
                        <li> Отсканируйте вышеперечисленные документы на сканере или на смартфоне, но обязательно
                            качественно,
                            четко и, желательно цветной снимок. Расширения документов должны быть .jpg либо .pdf
                        </li>
                        <li> Оплатите стоимость обмена нужного вам вида на р/счет или карту клуба и сохраните квитанцию
                            об
                            оплате
                        </li>
                        <li> Пришлите на почту клуба (kfrt1@mail.ru) отсканированные квитанцию и документы</li>
                        <li> При получении клубом от РКФ в личном кабинете ссылки на родословную, мы вышлем цифровую
                            копию
                            на
                            Ваш e-mail или WatsApp .
                        </li>
                        <li> Оригинал родословной можно забрать в офисе клуба или заказать его доставку почтой РФ.</li>
                        <li> справки о статусе документа через чат в WatsApp 79063270442</li></ol>

                    <ul><li> вариант с посещением офиса клуба или филиала КФРТ в РТ</li></ul>

                       <p > (Казань, Павлюхина 100, ежедневно с 13 до 19 , суббота с 13 до 17 воскресенье выходной )</p>

                    <ol>
                        <li> Подготовьте метрику щенка, заполните печатными четкими буквами графы "владелец", "адрес
                        владельца"( по паспорту)</li>
                        <li> Скачайте и заполнитенит и "Соглаесие на обработку персональных данных", заполните все пустые
                        строчки, подпишитесь живой подписью. Бланки заявок есть в клубе, возможно заполнение завки
                        кинологом
                        клуба.</li>
                        <li> Привезите и сдайте, оплатите родословную в клубе.</li>
                        <li> При получении клубом от РКФ ссылки на родословную, вышлем цифровую копию на Ваш e-mail или
                        WatsApp.</li>
                        <li>Оригинал родословной можно забрать в офисе клуба или заказать его доставку почтой РФ.</li>
                        <li> справки о статусе документа через чат в WatsApp 79063270442 </li>
                    </ol>

                        <p> Родословную на собаку рекомедуется получить в возрасте до 12- 15 мес.</p>

                        <p className={exchange.attention}> Важно ! Документы на помет должны быть сданы клубом в РКФ не позднее 6 мес с момента рождения.
                        КФРТ.</p>
                        <p > Сдает документы на пометы регулярно, в течение 2 месяцев после актировки. Щенки могут получать
                        родословные с 4 х месячного возраста. Но в некоторых случаях торопиться с обменом не стоит, так
                        как
                        у некоторых пород есть требования к измерению роста и взвешиванию для определения принадлежности
                        к
                        ростовой разновидности. .
                        КФРТ рекомендует начать обмен метрики щенка на родословную в возрасте 6 мес.

                        В случае оставления собаки на переосмотр, о чем есть отметка в метрике щенка, необходимы
                        индивидуальный осмотр или посещение выставки или племенного смотра.
                        В случае утери метрики Вам необходимо обратиться за дубликатом в кинологическую организацию,
                        которая
                        выпустила метрику.</p>

                    <p className={exchange.attention}> Если у Вас возникли вопросы, то мы ответим на них по телефонам:

                        +7 9050210637 +7 905 025 74 67 +79063270442 8(843) 2640559</p>

                </div>
            </div>
        </div>
);
}
export default ExchangeOfMetricsForPedigree;