import React from "react";
import generalInformationPage from "./GeneralInformationPage.module.css"


const GeneralInformation = () => {
    return (
        <div>
            <div className={generalInformationPage.h1}>
                <h1>КИНОЛОГИЧЕСКАЯ ФЕДЕРАЦИЯ РЕСПУБЛИКИ ТАТАРСТАН (КФРТ)</h1>
            </div>
            <div >
                <div className={generalInformationPage.h3}>
                    <h3>Официальный клуб собаководства в республике Татарстан, представитель Российской Кинологической
                        Федерации ( РКФ) в Республике Татарстан.
                    </h3>
                </div>
                <p>КФРТ имеет статус юридического лица и является общественной организацией, зарегистрированной
                    Министерством юстиции РФ , в соответствии с действующим законодательством Российской Федерации.
                    КФ РТ является членом РКФ , в своей деятельности руководствуется правилами, положениями и
                    регламентами, принятыми в системе Российской Кинологической Федерации (РКФ), Международной
                    Кинологической Федерации (FCI).</p>
                <p>КФ РТ работает в г. Казань, филиалы нашего клуба работают в городах Татарстана Чистополь и
                    Зеленодольск, Волжск, Набережные Челны. В настоящее время членами клуба являются владельцы и
                    заводчики служебных, охотничьих и декоративных и пород собак.
                    Наш клуб был организован с целью объединения собаководов дляразвития собаководства в нашем регионе,
                    приятного общения ведущих кинологов , совместному проведению зоотехнических мероприятий и
                    профессиональной поддержке начинающих собаководов.
                    Председатель клуба - Гринь Ольга Анатольевна,
                    судья РКФ –FCI по все породам собак и рабочим качествам собак, кандидат биологических наук,
                    кермайстер, доктор ветеринарной медицины.</p>
                <p> В нашем клубе Вас всегда внимательно выслушают и проконсультируют по вопросам выбора породистой
                    собаки, кормления, выращивания, ветеринарии, разведения, окажут помощь в подборе пары, оформлении
                    документации на щенков, получению документов в РКФ.
                    В нашем клубе собраны наиболее компетентные профессиональные специалисты в области кинологии, для
                    которых собаководство не только призвание, но и профессия.</p>
                <p>В клубе Вы сможете пройти курс обучения на кинолога, заводчика или инструктора, научиться правильно
                    обращаться со своей собакой, пройти со своей собакой курсы общей и специальной дрессировки , а также
                    хэндлинга, груминга.
                    На базе КФР Т действует единственный в Казани и республике Татарстан Учебный центр для собаководов и
                    их питомцев, детская и юношеская кинологическая секция «ЮНЫЙ КИНОЛОГ», секция любителей дрессировки
                    , секция НОУЗ ВОРК, секция профессиональных хэндлеров и ринговых бригад.</p>
                <p>Добро пожаловать в Кинологическую Федерацию Татарстана, приглашаем Вас в сообщество ответственных
                    собаководов, заводчиков и любителей собак. Мы поддрержим любую инициативу владельца , который хочет
                    чтобы его собака стала лучшей среди равных.</p>
                    <p> <b>Кинологическая Федерация республики Татарстан – Ваш надежный партнер в мире кинологии.</b></p>
            </div>

        </div>
    );
}
export default GeneralInformation;