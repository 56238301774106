import React from "react";
import testPageModule from "./TestAndCompetitions.module.css";
import test from "../../../img/newsBlockImg/test_and_Competitions.jpeg";

const TestAndCompetitions = (props) => {
    return (

        <div>
            <br/>


            <div>
            <img className={testPageModule.displayed} src={test} />
            </div>
        </div>

);
}
export default TestAndCompetitions;