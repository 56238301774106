import React from "react";
import copyrightModule from "./Copyright.module.css";

const Copyright = (props) => {
    return (
        <div>
            <h1 className={copyrightModule.h1}> ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h1>
            <h3 className={copyrightModule.h3}>Настоящее Соглашение определяет условия использования Пользователями материалов и сервисов сайта
                www.kfrt.ru (далее — «Сайт»).</h3>
            <ol className={copyrightModule.text}>
                <li>Общие условия</li>
                <ul>
                <li> Использование материалов и сервисов Сайта регулируется нормами действующего законодательства
                    Российской Федерации.</li>
                    <li> Настоящее Соглашение является публичной офертой. Получая доступ к материалам Сайта Пользователь
                    считается присоединившимся к настоящему Соглашению.</li>
                        <li> Администрация Сайта вправе в любое время в одностороннем порядке изменять условия настоящего
                    Соглашения. Такие изменения вступают в силу по истечении 3 (Трех) дней с момента размещения новой
                    версии Соглашения на сайте. При несогласии Пользователя с внесенными изменениями он обязан
                    отказаться от доступа к Сайту, прекратить использование материалов и сервисов Сайта.
                        </li>
                </ul>
                <li>Обязательства Пользователя</li>
                <ul>
                <li> Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие
                российское законодательство или нормы международного права, в том числе в сфере интеллектуальной
                собственности, авторскихи/или смежных правах, а также любых действий, которые приводят или могут
                    привести к нарушению нормальной работы Сайта и сервисов Сайта.</li>
               <li> Использование материалов Сайта без согласия правообладателей не допускается (статья 1270 Г.К РФ).
                Для правомерного использования материалов Сайта необходимо заключение лицензионных договоров (получение
                   лицензий) от Правообладателей.</li>
                <li> При цитировании материалов Сайта, включая охраняемые авторские произведения, ссылка на Сайт
                    обязательна (подпункт 1 пункта 1 статьи 1274 Г.К РФ).</li>
                <li> Комментарии и иные записи Пользователя на Сайте не должны вступать в противоречие с требованиями
                    законодательства Российской Федерации и общепринятых норм морали и нравственности.</li>
                <li> Пользователь предупрежден о том, что Администрация Сайта не несет ответственности за посещение и
                    использование им внешних ресурсов, ссылки на которые могут содержаться на сайте.</li>
                <li> Пользователь согласен с тем, что Администрация Сайта не несет ответственности и не имеет прямых или
                косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или
                убытками, связанными с любым содержанием Сайта, регистрацией авторских прав и сведениями о такой
                регистрации, товарами или услугами, доступными на или полученными через внешние сайты или ресурсы либо
                иные контакты Пользователя, в которые он вступил, используя размещенную на Сайте информацию или ссылки
                    на внешние ресурсы.</li>
                <li> Пользователь принимает положение о том, что все материалы и сервисы Сайта или любая их часть могут
                сопровождаться рекламой. Пользователь согласен с тем, что Администрация Сайта не несет какой-либо
                    ответственности и не имеет каких-либо обязательств в связи с такой рекламой.</li>
                </ul>
                <li> Прочие условия</li>
                <ul>
               <li> Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в
                соответствии с действующим законодательством Российской Федерации.</li>
                    <li> Ничто в Соглашении не может пониматься как установление между Пользователем и Администрации Сайта
                агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного
                найма, либо каких-то иных отношений, прямо не предусмотренных Соглашением.</li>
                    <li> Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному
                исполнению не влечет недействительности иных положений Соглашения.</li>
                    <li> Бездействие со стороны Администрации Сайта в случае нарушения кем-либо из Пользователей положений
                Соглашения не лишает Администрацию Сайта права предпринять позднее соответствующие действия в защиту
                своих интересов и защиту авторских прав на охраняемые в соответствии с законодательством материалы
                Сайта.</li>
                    <li>Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего Соглашения и безусловно принимает
                их.</li>
                </ul>
            </ol>
        </div>
    )
}
export default Copyright;