import './App.css';
import Header from "./components/Header/Header";
import NavBar from "./components/NavBar/NavBar";
import Club from "./components/Content/AboutTheClub/Club";
import Footer from "./components/footer/Footer";
import NewsBlock from "./components/Content/NewsBlock/NewsBlock";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Copyright from "./components/Pages/copyright/Copyright";
import Fellowship from "./components/Pages/fellowship/Fellowship";
import BrandingAndChipping from "./components/Pages/brandingAndChipping/BrandingAndChipping";
import MainPage from "./components/Pages/index/MainPage";
import News from "./components/Pages/news/News";
import GeneralInformation from "./components/generalnformation /GeneralInformation";
import PartnerClubsAndBranchesOfKFT from "./components/partnerclubs/PartnerClubsAndBranchesOfKFT";
import Expert from "./components/Pages/expert/Expert";
import ExchangeOfMetricsForPedigree from "./components/Pages/exchangeofmetricsforpedigree/ExchangeOfMetricsForPedigree";
import NotFound from "./components/notfound/NotFound";
import DogOwner from "./components/Pages/dogOwner/DogOwner";
import BreedSelection from "./components/Pages/breedSelection/BreedSelection";
import TestAndCompetitions from "./components/Pages/testAndCompetitions/TestAndCompetitions";
import RussenRottenshildClub from "./components/Pages/russen rottenshild/RussenRottenshildClub";

const App = () => {
    return (
        <BrowserRouter>
            <div className='app-wrapper'>
                <Header/>
                <NewsBlock/>
                <NavBar/>
                <Footer/>
                <div className='app-wrapper-content'>
                    <Routes>
                        <Route path="/" element={<MainPage/>}/>
                        <Route path="/Copyright" element={<Copyright/>}/>
                        <Route path="/Fellowship" element={<Fellowship/>}/>
                        <Route path="/Club" element={<Club/>}/>
                        <Route path="/BrandingAndChipping" element={<BrandingAndChipping/>}/>
                        <Route path="/News" element={<News/>}/>
                        <Route path="/GeneralInformation" element={<GeneralInformation/>}/>
                        <Route path="/PartnerClub" element={<PartnerClubsAndBranchesOfKFT/>}/>
                        <Route path="/Expert" element={<Expert/>}/>
                        <Route path="/ExchangeOfMetricsForPedigree" element={<ExchangeOfMetricsForPedigree/>}/>
                        <Route path="/notFound" element={<NotFound/>}/>
                        <Route path="/DogOwner" element={<DogOwner/>}/>
                        <Route path="/BreedSelection" element={<BreedSelection/>}/>
                        <Route path="/testAndCompetitions" element={<TestAndCompetitions/>}/>
                        <Route path="/RussenRottenshildClub" element={<RussenRottenshildClub/>}/>
                    </Routes>


                </div>
            </div>
        </BrowserRouter>)
}
export default App;
