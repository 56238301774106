import React from "react";
import footerModule from "./Footer.module.css";

const Footer = () => {
    return <div className={footerModule.footer}>
        <div className={footerModule.text}>
            <p>Офис: г. Казань ул. Павлюхина д. 100
                Учебный центр: г. Казань ул. Ферма-2 д. 76
                тел.: 8(906) 327-04-42, 8(905) 021-06-37
                e-mail: kfrt1@mail.ru
            </p>
        </div>
    </div>
}

export default Footer;