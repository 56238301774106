import React from "react";
import brandingAndChipping from "./BrandingAndChipping.module.css";

const BrandingAndChipping = (props) => {
    return (
        <div>

            <div >
                <h3 className={brandingAndChipping.h3}>Идентификация собак осуществляется посредством клеймения или чипирования.</h3>
            </div>
            <div>
                <p className={brandingAndChipping.text}>
                    Клеймение - это нанесение на тело собаки татуировки, содержащие буквы FRT и цифры (согласно
                    Племенной книге КФРТ). Клеймо определяется клубом КФРТ и наносится СПЕЦИАЛИСТОМ КЛУБА на правое ухо
                    или правый пах собаки при актировании в возрасте 45 дней щенкам средних пород и в возрасте 60 дней
                    щенкам мелких пород.
                </p>
            </div>
            <div>
                <p className={brandingAndChipping.text}>КЛЕЙМЕНИЕ ЯВЛЯЕТСЯ ОБЯЗАТЕЛЬНЫМ ДЛЯ ВСЕХ ЩЕНКОВ РОЖДЕННЫХ В РФ РКФ . Клеймение проводится в
                    присутствии кинолога клуба и заводчика, которые удостоверяют принадлежность щенка к помету, щенок
                    должен быть указан под клеймом в акте вязки и общепометной карте.</p>
            </div>
            <div>
                <p className={brandingAndChipping.text}>В КФРТ возможно клеймение взрослых собак по предварительному заявлению владельца на основании метрики
                    или родословной при письменом разрешении заводчика или при регистрации собаки для первичной
                    родословной. </p>
            </div>
            <div>
                <p className={brandingAndChipping.text}>
                    Клеймение проводится с помощью специальных татуажных щипцов для мелких животных, размером 7мм или
                    5мм или татуажной ручкой краской фирмы HAUPTNER.
                </p>
            </div>
            <div>
                <p className={brandingAndChipping.text}>
                    В дальнейшем клеймо служит для идентификации собаки на выставках, племенных смотрах, соревнованиях,
                    испытаниях, поможет найти животное в случае утери и розыска.
                </p>
            </div>
            <div>
                <p className={brandingAndChipping.text}>Чипирование собак – это введение под кожу собаки ( обычно область холки или правого плеча,
                    биостабильной капсулы размером 8х2 мм ( применый размер рисового зерна). Капсула содержит
                    электронный чип, с идентификационным номером , который сразу после введения чипа вносится в
                    электронную базу данных с информацией о носителе чипа, а владелец получает свидетельство о
                    чипировании животного , штрих код чипа вносится в ветеринарный паспорт и родословную.
                    Процедура чипирования похожа на обычную подкожную инъекцию ( укол).
                    Чип вводят под кожу в области холки или правого плеча собаки при помощи специального шприца,
                    предварительно кожа дезинфицируется в месте введения . В течение 7-14 недель чип обрастает
                    соединительной тканью, закрепляется не причиняя беспокойство питомцу. Материал капсулы биостабилен,
                    исключают развитие воспалительных и аллергических реакций.
                    Что значит уникальный номер чипа?
                    Ппример- номер микрочипа 643098433336787
                    <ul>
                        <li>643 – код России</li>
                        <li>0 – код разделения</li>
                        <li>984 – код производителя</li>
                        <li>33336787 – собственно уникальный номер чипа.</li>
                    </ul>
                </p>
            </div>
            <di>
                <h2 className={brandingAndChipping.h2}>Как информация о чипе и животном отображается в базе данных?</h2>
                <p className={brandingAndChipping.text}>
                    Набрав в базе данных соответствующей паспорту чипирования номер чипа , можно получить сведения о
                    животном:
                    <ul>
                        <li>Кличка</li>
                        <li>Вид и пол</li>
                        <li>Фото питомца (по желанию владельца)</li>
                        <li>Порода (если необходимо)</li>
                        <li>Дата рождения</li>
                        <li>Окрас и особые приметы</li>
                        <li>ФИО владельца</li>
                        <li>Контакты владельца (адрес, e-mail, телефон)</li>
                        <li>сведения об организации, где проводилось чипирование.</li>
                    </ul>
                </p>
            </di>
            <div>
                <h2 className={brandingAndChipping.h2}>Для чего нужно чипирование</h2>
                <p className={brandingAndChipping.text}>
                    <ol>
                        <li>Для путешестий или переезде на ПМЖ с животным за границу. В большинстве стран запрещен ввоз
                            нечипированных животных. Это страны Евросоюза, Израиль, страны Юго восточнй Азии, Северной и
                            Южной Америки.
                        </li>
                        <li>Идентификация в случае потери или кражи животного. Найти и вернуть чипированную собаку можно
                            с более высокой вероятностью.
                        </li>
                        <li>Участие в международных выставках и соревнованиях. При регистрации требуется обязательное
                            чипирование.
                        </li>
                        <li>Если по ветеринарным показания клеймение невоможно.</li>
                    </ol>
                </p>
            </div>
            <div>
                <h2 className={brandingAndChipping.h2}>Часто задаваемые вопросы о чипировании домашних животных</h2>
                <p className={brandingAndChipping.text}>
                    <ol>

                        <li>С какого возраста можно проводить процедуру?</li>
                        <ul>
                            <li>Щенков лучше чипировать непосредственно вместе с первой вакцинацией, в возрасте
                                пять-шесть недель. Взрослые животные могут быть чипированы при необходимости в любом
                                возрасте.
                            </li>
                        </ul>
                        <li>Можно ли определить местоположение потерявшегося животного с чипом?</li>
                        <ul>
                            <li>Нет, так как чип - это не навигатор, найти животное можно только после того как его
                                найдут,
                                считают чип и свяжутся с владельцем. Если ваш питомец часто теряется используйте
                                адресники или
                                современные GPS ошейники с датчиками.
                            </li>
                        </ul>
                        <li>Как часто необходимо чипировать?</li>
                        <ul>
                            <li>Чипирование проводится один раз в жизни собаки.</li>
                        </ul>
                        <li>Какие существуют противопоказания для чипирования?</li>
                        <ul>
                            <li>Заболевания в острой стадии, аллергия на антисептические препараты . Животные в возрасте
                                до 1
                                месяца.
                            </li>
                        </ul>
                        <li>Каковы вероятные осложнения после введения чипа?</li>
                        <ul>
                            <li>Каковы вероятные осложнения после введения чипа?
                                При правильном уходе вероятность развития осложнений минимальна, так как единственное
                                возможное
                                осложнение – инфицирование места введения. не позволяйте питомцу вылизывать и
                                расчесывать место
                                укола, не купайте собаку или кошку 3дня после процедуры.
                            </li>
                        </ul>
                    </ol>
                </p>
            </div>
            <div>
                <p className={brandingAndChipping.text}>
                    В отличие от обязательного клеймения, чипирование в РФ добровольное.
                    ДОБРОВОЛЬНОЕ ЧИПИРОВАНИЕ
                    Идентификация собаки сегодня является одним из объективных способов подтверждения прав владельца на
                    собаку. Согласно законодательству Российской Федерации животное является Вашей собственностью. Ваша
                    собственность на собаку подвержена документами: родословной и договором купли продажи, но в случае
                    утери ли и кражи собаки с ее насильственным удержанием в чужих руках, только чип может доказать ваше
                    право на животное в случае судебного разбирательства по установлению права собственности.

                </p>
            </div>
            <div>
                <p className={brandingAndChipping.text}>
                    ОБЯЗАТЕЛЬНОЕ ЧИПИРОВАНИЕ применяется с 3 июля 2004 г. на территории Европейского Союза (ЕС) согласно
                    Регламенту Совета и Европейского парламента ЕС № 998/2003

                    Регламент устанавливает условия перевозки домашних животных (кошек, собак и хорьков) между странами
                    ЕС, а также при ввозе в страну-член ЕС. Основным его пунктом является обязательность идентификации
                    животного, то есть, каждое животное должно иметь имплантированный микрочип, соответствующий
                    стандарту ISO 11784 или ISO 11785.
                    Чипировать животное должен только сертифицированный ветернинарний врач.

                </p>
            </div>
            <div>
                <p className={brandingAndChipping.text}>
                    Чипировать питомца вы можете в клубе КФРТ в рабочие дни с 14 до 19 ч.
                </p>
            </div>
            <div>
                <p className={brandingAndChipping.text}>
                    Запись предварительная по тел. 89063270442
                </p>
            </div>
        </div>
    )
}
export default BrandingAndChipping;